import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/DashboardSlider.css";
import { baseURL } from "../services/Aut.post.js";
import GameImg from "./images/game-img-1.png";
import { useLocation } from "react-router-dom";

import { Link, useNavigate } from "react-router-dom";

function GameStepTwo() {
  const contest_name = JSON.parse(localStorage.getItem("contest_name"));
  const sponcer_name = JSON.parse(localStorage.getItem("sponcer_name"));
  const gamedemolink = JSON.parse(localStorage.getItem("game_demo_link"));
  const Basegame = JSON.parse(localStorage.getItem("Basegame"));
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  const winningtype = JSON.parse(localStorage.getItem("winningtype"));
  const prize = JSON.parse(localStorage.getItem("prize"));
  const AnnouncementDate = JSON.parse(localStorage.getItem("AnnouncementDate"));
  const No_of_winners = JSON.parse(localStorage.getItem("No_of_winners"));
  const stageno = JSON.parse(localStorage.getItem("stageno"));
  const moves = JSON.parse(localStorage.getItem("moves"));
  const time = JSON.parse(localStorage.getItem("time"));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contest = searchParams.get("contest");


  // alert("KBKDFBN" , contest);
  // console.log("contestcontestcontest" , contest)


  // Function to handle checkbox click for Game T&C
  const handleTermsClick = (e) => {
    if (e.target.id === "gameTandc") {
      setShowTermsPopup(true);
    }
  };

  // Function to handle closing of the popup
  const handleClosePopup = () => {
    setShowTermsPopup(false);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const gameValue = urlParams.get("contest");

  const navigate = useNavigate();
  const handleClick = () => {
    const gameTandcChecked = document.getElementById("gameTandc").checked;
    const bigbTandcChecked = document.getElementById("bigbTandc").checked;
    if (gameTandcChecked && bigbTandcChecked) {
      const fetchGameSettings = async () => {
        try {
          const response = await axios.get(
            `${baseURL}/userapi/getcontestssettings/`
          );
          console.log(response.data);
          localStorage.setItem("gridcount", response.data.grid);
        } catch (error) {
          console.error("Error fetching game settings:", error);
        }
      };
      fetchGameSettings(); // Fetch dynamic settings when the component mounts
      navigate(`/Gamep?contest=${gameValue}&stage=1`);
    } else {
      alert(
        "Please check both Game Terms & Conditions and BigBonanza Terms & Conditions."
      );
    }
  };

  const [contestData, setContestData] = useState([]);

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/userapi/`, null, {
          headers,
        });
        setContestData(response.data); // Update the state with fetched data
      } catch (error) {
        console.error("Error fetching contests:", error);
      }
    };
    fetchData(); // Call the fetchData function
  }, []); // Include accessToken in the dependency array

  const fetchGameSettings = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/userapi/usersingupvaluesview/`
      );
      console.log(response.data);
      localStorage.setItem("gridcount", response.data.grid);
    } catch (error) {
      console.error("Error fetching game settings:", error);
    }
  };

  const handleClickLuck = async () => {
    try {
      const response = await fetch(
        `${baseURL}/tockens_api/luckygame_list/${contest}/`
      );
      const data = await response.json();

      if (data.status === "success" && data.surveys.length > 0) {
        const { _id, collection_name } = data.surveys[0];

        navigate(`/getluckGamedetails/${_id}/${collection_name}`);
      } else {
        console.error("No surveys found or API failed");
      }
    } catch (error) {
      console.error("Error fetching lucky game data:", error);
    }
  };

  const handleOfflineGame = () =>{
    navigate("/offline-game")
  }

  useEffect(() => {
    fetchGameSettings(); // Fetch dynamic settings when the component mounts
  }, []);

  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper paragraph-1">
          <h1>{contest_name}</h1>
          <h6>Sponsored by {sponcer_name}</h6>
          <div className="DashboardSlider">
            <div className="video-wrap-main">
              <div className="video-wrap-1">
                <p>{prize}</p>
                <ul>
                  <li>Winning Type: {winningtype}</li>
                  <li>No of Stages: {stageno}</li>
                  <li>No of Lives: {moves}</li>
                </ul>
              </div>
              <div className="video-wrap-2">
                <h4>How to Play</h4>
                <iframe
                  width="100%"
                  height="315"
                  src={gamedemolink}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className="TermsConditionsBox">
              <div className="form-group">
                <input type="checkbox" id="gameTandc" />
                <label htmlFor="gameTandc">
                  Agree{" "}
                  {/* Trigger popup when "Game Terms & Conditions" text is clicked */}
                  <span
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => setShowTermsPopup(true)}
                  >
                    Game Terms & Conditions
                  </span>
                </label>
              </div>
            </div>

            {showTermsPopup && (
              <div className="popup-overlay">
                <div className="popup-content">
                  <h2>Game Terms & Conditions</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry’s
                    standard dummy text ever since the 1500s...
                  </p>
                  <a className="btn-2" onClick={handleClosePopup}>
                    Close
                  </a>
                </div>
              </div>
            )}

            <div className="TermsConditionsBox">
              <div className="form-group">
                <input type="checkbox" id="bigbTandc" />
                <label htmlFor="bigbTandc">
                  <a
                    href="/BigBonanzaTermsandConditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    BigBonanza Terms & Conditions
                  </a>
                </label>
              </div>
            </div>

            {/* <div className="text-center pt-3 pb-5">
              <button onClick={handleClick} className="btn-2">
                Launch
              </button>
            </div> */}
            <div className="text-center pt-3 pb-5">
              {Basegame === "luckyform" ? (
                <button onClick={handleClickLuck} className="btn-2">
                  Launch
                </button>
              ) : Basegame === "offline" ? (
                <button onClick={handleOfflineGame} className="btn-2">
                  Launch
                </button>
              ) : (
                <button onClick={handleClick} className="btn-2">
                  Launch
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameStepTwo;
