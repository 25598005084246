import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InnerBanner from "./InnerBanner";
import congratulations from "./images/congratulations.png";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import "./Styles/DashboardSlider.css";

const OfflineResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const resultData = location.state || {};
  const { width, height } = useWindowSize();

  return (
    <>
      <InnerBanner />
      {/* Show Confetti only if the user wins */}
      {resultData.status === "success" && <Confetti width={width} height={height} />}
      
      <div className="DashboardSlider-bg-1">
        <div className="wrapper">
          <div className="congratulations-sec">
            {/* Show congratulations image only if the user wins */}
            {resultData.status === "success" && (
              <img src={congratulations} alt="Congratulations" className="congratulations-img" />
            )}
            {resultData.status === "success" ? (
              <h2 className="congrats-text">Hooray! Please collect your Compliment!</h2>
            ) : (
              <h2 className="failure-text">Better luck next time!</h2>
            )}
            <img src={resultData.image} alt="Result" className="result-img" />
          </div>

          {/* Centered button */}
          <div className="btn-container">
            <Link to="/Dashboard" className="btn-2">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfflineResult;
