
import React from 'react';
import "./Styles/ExtraOffers.css";
import successlogo from "./images/sucess-msg.png"
import InnerBanner from "./InnerBanner";

function SurveyConfrim() {
  
  const handleClick = () => {
    const redirectUrl = `/`;
    window.location.href = redirectUrl;
  };


  return (
    <>
    <InnerBanner /> 
      <div className="top-space-1">
        <div className="claim-page">
          <div className="claim-page-bg">
          <img src={successlogo} alt="logo" />
          <br/>
            <h4>Your survey has been successfully submitted!</h4>
            <h3>Thank you for your feedback.</h3>
            <p>Your response has been recorded <br/>
            Feel free to explore our latest Deals and Shopping.</p>
            <button class="button-orange-1 w-80" onClick={handleClick}>Back to Home</button>
          </div>     
        </div>
      </div>
    </>
  );
}

export default SurveyConfrim;
