import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Styles/DashboardSlider.css";
import biscuit from "./images/Biscuit.png"; // Import the success image
import biscuit2 from "./images/biscuit2.png"; // Import the success image
import soda from "./images/soda.png"; // Import the success image
import wafer from "./images/wafer.png"; // Import the success image
import chips from "./images/chips.png"
import failureImage from "./images/offer-ended.jpg"; // Import the failure image
import { baseURL } from "../services/Aut.post";

const Offline = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [serialNumber, setSerialNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [randomImage, setRandomImage] = useState(null);

  let sequenceNumber = 1;

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  // Sanitize function to remove extra quotes
  const cleanString = (str) => str ? str.replace(/^"|"$/g, "") : "";

  // Get values from localStorage and clean them
  const contast_name = cleanString(localStorage.getItem("contest_name"));
  const contast_id = cleanString(localStorage.getItem("contestid"));
  const contast_type = cleanString(localStorage.getItem("winningtype"));
  const winning_items = cleanString(localStorage.getItem("prize"));
  const user_type = cleanString(localStorage.getItem("Usertype"));

  const SqNo = serialNumber;

  useEffect(() => {
    generateSerialNumber(); // Generate SQ No. automatically on component mount
  }, []);

  const generateSerialNumber = () => {
    const seqNo = String(sequenceNumber).padStart(4, "0"); // 4-digit sequence number (0001, 0002, ...)
    const randomPin = Math.floor(1000 + Math.random() * 9000); // 4-digit random PIN
  
    const serial = `${seqNo}${randomPin}`;
    setSerialNumber(serial);
    console.log(serial); // Output the serial number
  
    sequenceNumber++; // Increment sequence for next call
    return serial;
  };

  // Success images (5 random images)
  const successImages = [
    biscuit, 
    biscuit2, 
    wafer, 
    soda, 
    chips,
  ];

  // Failure image
  const failureImg = failureImage;

  const handleClickLuck = async () => {
    if (!serialNumber) {
      return;
    }
  
    setLoading(true);
  
    const formData = {
      contest_type: contast_type,
      contest_id: contast_id,
      contest_name: contast_name,
      winning_item: winning_items,
      sequence_no: SqNo,
      user_type: user_type,
    };
  
    console.log("Form Data:", formData);
    console.log("Headers:", headers);
  
    try {
      const response = await axios.post(
        `${baseURL}/tockens_api/offline-contest-data-insert/`,
        formData,
        { headers }
      );
  
      console.log("Response:", response.status);
  
      let resultData = {};
      if (response.status === 200 || response.status === 201) {
        // Success case - Select a random success image
        resultData = {
          status: "success",
          image: successImages[Math.floor(Math.random() * successImages.length)],
        };
      } else {
        // Failure case - Use failure image
        resultData = {
          status: "failure",
          image: failureImg,
        };
      }
  
      // Navigate to OfflineResult with data
      navigate("/offlineResult", { state: resultData });
  
    } catch (error) {
      console.error("Error submitting data:", error);
  
      // Failure case
      navigate("/offlineResult", {
        state: { status: "failure", image: failureImg },
      });
  
    } finally {
      setLoading(false);
    }
  };
  

  const handleClosePopup = () => {
    setPopupVisible(false);
    navigate("/dashboard"); // Navigate to home after closing the popup
  };

  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper paragraph-1">
          <div className="offline-container">
            <div className="offline-card">
              <div className="DashboardSlider">
                <div className="video-wrap-main">
                  <div className="offline-wrap-1">
                    <ul>
                      <li>Contest Name: {contast_name}</li>
                      <li>Winning Prize: {winning_items}</li>
                      <li>Date: {date.toLocaleDateString()}</li>
                      <li>Sq No: {SqNo}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center pt-3 pb-5">
            <button onClick={handleClickLuck} className="btn-2" disabled={loading}>
              Done
            </button>
          </div>
        </div>
      </div>

      {/* Popup for displaying random image */}
{popupVisible && (
  <div className="popup">
    <div className="popup-content">
      {/* Show congratulations text only if the random image is a success image */}
      {successImages.includes(randomImage) && <h2 className="congrats-text">Congratulations! You won!</h2>}
      <img src={randomImage} alt="Random" />
      <button onClick={handleClosePopup} className="close-btn">X</button>
    </div>
  </div>
)}

    </div>
  );
};

export default Offline;
