import React, { useState, useEffect } from 'react';

const PaymentComponent = () => {
    const [paymentData, setPaymentData] = useState(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://jssdk.payu.in/bolt/bolt.min.js'; // PayU SDK URL
        script.async = true;
        script.onload = () => {
          console.log("payu loaded successfully")
        };
        document.body.appendChild(script);
    }, []);

    const handlePayment = async () => {
        try {
            const response = await fetch('/initiate_payment/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: new URLSearchParams({
                    amount: 100, // Example amount
                    productInfo: 'Test Product',
                    email:"test@gmail.com",
                    phone:9999999999,
                    firstName:"test",
                    lastName:"user"
                }).toString(),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setPaymentData(data);

            // Initialize PayU and perform checkout
            if (window.PayU && data) {
                window.PayU.checkout(data, (response) => {
                  console.log(response)
                    if (response.status === 'success') {
                        // Payment successful
                        console.log('Payment successful:', response);
                    } else {
                        // Payment failed or cancelled
                        console.error('Payment failed:', response);
                    }
                });
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
        }
    };

    function getCookie(name) {
      // ... (cookie retrieval function as before)
    }

    return (
        <div>
            <button onClick={handlePayment}>Pay with PayU</button>
        </div>
    );
};

export default PaymentComponent;