
import React, { useState, useEffect } from 'react';
import InnerBanner from "./InnerBanner";
import "./Styles/Winnings.css";
//import { Link } from "react-router-dom";
import AddressEditIcon from "./images/winning.png";
import ActiveImg from "./images/active.png";
import {baseURL } from "../services/Aut.post.js";
import axios from "axios";
function Winnings() {
  const [showPopup, setShowPopup] = useState(false);
  const [totalWinningData, setTotalWinningData] = useState({ results: [] });
  const [contestData, setContestData] = useState([]);

  const [selectedContest, setSelectedContest] = useState('');
  const [totalContestWinningData, setTotalContestWinningData] = useState({ result: [] });
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };
  useEffect(() => {
    // Set showPopup to true after component mounts
    setShowPopup(true);

    const getBannerImages = async () => {
    
    
      try {
        const response = await axios.get(`${baseURL}/tockens_api/list_contest_results/1/`, { headers });
      
        setTotalWinningData(response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching user account status:', error);
        throw error;
      }
    };
    getBannerImages()
  }, []);

  useEffect(() => {
   
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/userapi/getwinningcontests/`, { headers });
        console.log(response.data)
        setContestData(response.data); // Update the state with fetched data
      } catch (error) {
        console.error('Error fetching contests:', error);
        // Handle error gracefully
      }
    };
  
    fetchData(); // Call the fetchData function
  }, []); // Include accessToken in the dependency array

  const handleClose = () => {
    setShowPopup(false);
  };

  const getSelectedContest = async (contestID) => {
    try {
      const response = await axios.get(`${baseURL}/tockens_api/list_contest_results/${contestID}/`, { headers });
      console.log(response.data); // Correct console statement
      setTotalContestWinningData(response.data); // Update state with API data
    } catch (error) {
      console.error('Error fetching contest results:', error);
    }
  };

  const handleSelectChange = (event) => {
    const contestID = event.target.value;
    
    setSelectedContest(contestID); // Update selected contest ID
    if (contestID) {
      getSelectedContest(contestID); // Call API with selected contestID
    }
  };


  return (
    <div>
       {/* {showPopup && (
        <div className="Winnings-popup">
          <div className="Winnings-popup-content">
            <h1>Disclaimer</h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <button className='btn-2' onClick={handleClose}>Continue</button>
          </div>
        </div>
      )} */}
      <InnerBanner />
      <div className="Winnings-bg-1">
        <div className="wrapper">
          <h1>Winnings </h1>
          <h6>
            {/* User Level: Pro <span> <img src={ActiveImg} alt={ActiveImg} /> Verified{" "} </span> */}
          </h6>

          <h3>All the My Winnings</h3>
          <div className="Winnings-wrap">
          {totalWinningData.results.length > 0 ? (
        totalWinningData.results.map((contestUserWin, index) => (
            <div className="Winnings-box">
              <div className="wb-1">
                <img src={AddressEditIcon} alt={AddressEditIcon} />
              </div>
              {/* <div className="wb-2">Best1</div> */}
            
          
              <div className="wb-3">
              <span className="wb-2">Contest: </span>
                 {contestUserWin.contest_name}
                <br></br>
                <span className="wb-2">Prize: </span>

                {contestUserWin.winning_item}
                <br></br>
                <span className="wb-2">Date : </span>
                
                
                {new Date(contestUserWin.date_time).toLocaleString()}
              </div>
                 
            </div>

          
))
) : (
  <p>No contest results found.</p>
)}

        

           

          </div>
          {/* <button className="btn-2">View All</button> */}
          <hr />

          <h3>Recent Winners</h3>
          <div className="Select-Contest">
            <p>Select Contest</p>
            <select onChange={handleSelectChange} value={selectedContest}>
          <option value="">Select</option>
          {contestData.map((contest) => (
            <option key={contest.contestID} value={contest.contestID}>
              {contest.ContestName}
            </option>
          ))}
        </select>

            

          </div>
          <div className="Winnings-wrap">


          {totalContestWinningData.result && totalContestWinningData.result.length > 0 ? (
        totalContestWinningData.result.map((result, index) => (
          <div className="Winnings-box" key={index}>
            <div className="wb-1">
              <img src={AddressEditIcon} alt={AddressEditIcon} />
            </div>
            {/* <div className="wb-2">1</div> */}
            <div className="wb-3">
            <span className="wb-2">Name: </span>{result.full_name}
             <br></br>
             <span className="wb-2">Prize: </span>{result.winning_item}
             <br></br>
            <span className="wb-2">Prize: </span>{result.contest_name}
            <br></br>
             <span className="wb-2">Prize: </span>{new Date(result.date_time).toLocaleString()}
            </div>
          </div>
        ))
      ) : (
        <p>No winning results available.</p>
      )}
         

            

          

            
            </div>

        </div>
      </div>
    </div>
  );
}

export default Winnings;
