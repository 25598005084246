import React, { useState } from 'react';
import axios from 'axios';

const PayUCheckout = () => {
    const [formData, setFormData] = useState({
        amount: 100,
        productinfo: "Test Product",
        firstname: "John",
        email: "john@example.com",
        phone: "1234567890",
        txnid: `TXN${Date.now()}`, // Generate a unique transaction ID
    });

    const handleCheckout = async () => {
        try {
            const response = await axios.post('http://127.0.0.1:8000/tockens_api/generate-signature/', formData);
            const { signature } = response.data;

            const payUConfig = {
                key: "your_merchant_key",
                txnid: formData.txnid,
                amount: formData.amount,
                productinfo: formData.productinfo,
                firstname: formData.firstname,
                email: formData.email,
                phone: formData.phone,
                surl: "http://localhost:3000/success", // Success callback
                furl: "http://localhost:3000/failure", // Failure callback
                hash: signature,
                service_provider: "payu_paisa",
            };

            // Redirect to PayU hosted checkout
            const form = document.createElement("form");
            form.method = "POST";
            form.action = "https://test.payu.in/_payment";

            for (const [key, value] of Object.entries(payUConfig)) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error("Error generating signature:", error);
        }
    };

    return (
        <div>
            <h2>PayU Checkout</h2>
            <h2>PayU Checkout</h2><h2>PayU Checkout</h2><h2>PayU Checkout</h2><h2>PayU Checkout</h2><h2>PayU Checkout</h2><h2>PayU Checkout</h2><h2>PayU Checkout</h2><h2>PayU Checkout</h2><h2>PayU Checkout</h2>

            <button onClick={handleCheckout}>Pay Now1</button>
        </div>
    );
};

export default PayUCheckout;
