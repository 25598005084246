import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner";
import "./Styles/Winnings.css";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js";

function Winnings() {
  const [showPopup, setShowPopup] = useState(false);
  const [contestData, setContestData] = useState([]);
  const [selectedContest, setSelectedContest] = useState("");
  const [contestWinningData, setContestWinningData] = useState(null);

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken?.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const response = await axios.get(`${baseURL}/userapi/getLuckyContests/`, { headers });
        console.log("Fetched Contests:", response.data);
        setContestData(response.data);
      } catch (error) {
        console.error("Error fetching contests:", error);
      }
    };

    fetchContests();
  }, []);

  const handleSelectChange = async (event) => {
    const contestID = event.target.value;
    setSelectedContest(contestID);

    if (contestID) {
      try {
        const selectedUser = contestData.find((c) => c.contestID === contestID)?.user_id;
        if (!selectedUser) return;

        const response = await axios.get(
          `${baseURL}/tockens_api/luckygame_list_entrys/${contestID}/${selectedUser}`,
          { headers }
        );
        // alert("bidkjbij" , response)
        console.log("Fetched Contest Winning Data:", response.data);
        setContestWinningData(response.data);
      } catch (error) {
        console.error("Error fetching contest results:", error);
      }
    }
  };

  // Extract user response data
  const selectedUser = contestData.find((c) => c.contestID === selectedContest)?.user_id;
  const responses = contestWinningData?.surveys?.[0]?.responses || [];
  
  console.log("DBKGBG" , responses);
  const responseEntries = responses.find((resp) => resp.user_id !== selectedUser) || {};

  console.log("Extracted Response Entries:", responseEntries);

  return (
    <div>
      <InnerBanner />
      <div className="Winnings-bg-1">
        <div className="wrapper">
          <h1>Entries</h1>

          <div className="Select-Contest">
            <p>Select Contest</p>
            <select onChange={handleSelectChange} value={selectedContest}>
              <option value="">Select</option>
              {contestData.map((contest) => (
                <option key={contest.contestID} value={contest.contestID}>
                  {contest.ContestName}
                </option>
              ))}
            </select>
          </div>

          {selectedContest && (
            <div className="Contest-Response">
              <hr />
              {Object.keys(responseEntries).length > 0 ? (
                <div className="Winnings-wrap">
                  {/* <p><strong>Submitted At:</strong> {responseEntries.submitted_at || "N/A"}</p> */}
                  
                  {/* Display Selected Options */}
                  {responseEntries.selectedOptions && Object.keys(responseEntries.selectedOptions).length > 0 && (
                    <div>
                      <h3>Selected Option:</h3>
                      <ul>
                        {Object.entries(responseEntries.selectedOptions).map(([key, value]) => (
                          <li key={key}>
                            {key}: {typeof value === "string" ? value : JSON.stringify(value)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Display Text Answers */}
                  {responseEntries.textAnswers && Object.keys(responseEntries.textAnswers).length > 0 && (
                    <div>
                      <h3>Text Answer:</h3>
                      <ul>
                        {Object.entries(responseEntries.textAnswers).map(([key, value]) => (
                          <li key={key}>
                            {key}: {typeof value === "string" ? value : JSON.stringify(value)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Display Image if available */}
                  {responseEntries.imageUploads && Object.keys(responseEntries.imageUploads).length > 0 && (
                    <div className="entries-box" >
                      
                     <div style={{display:"flex" , flexDirection :"column"}}> <h3>Uploaded Image:</h3>
                      {Object.entries(responseEntries.imageUploads).map(([key, value]) => (
                        <img
                          key={key}
                          src={value.startsWith("http") ? value : `${baseURL}${value}`}
                          alt="Uploaded"
                          width="100"
                          className="display-image"
                        />
                      ))}
                      </div>
                      <br/>
                      <p><strong>Submitted At : </strong>{responseEntries.submitted_at.split("T")[0] || "N/A"}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <p>No responses found.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Winnings;
