import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import { Link } from "react-router-dom";
import "./Styles/ChangeAddress.css";
import { Form } from "react-bootstrap";
import axios from "axios";
import { getPanaandAadhar, baseURL } from "../services/Aut.post.js";

function MyAccountStatus() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const [formData, setFormData] = useState({
    aadharcard: "",
    pancard: "",
  });

  const [isUpdated, setIsUpdated] = useState(false); // Prevent further updates

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Form Validation
  const validateForm = () => {
    const { pancard, aadharcard } = formData;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // PAN format (e.g., ABCDE1234F)
    const aadharRegex = /^[0-9]{12}$/; // Aadhar should be 12 digits

    if (!panRegex.test(pancard)) {
      setErrorMessage("Invalid PAN Card format. Must be 10 characters (ABCDE1234F).");
      return false;
    }
    if (!aadharRegex.test(aadharcard)) {
      setErrorMessage("Invalid Aadhar Card number. Must be 12 digits.");
      return false;
    }

    setErrorMessage(""); // Clear errors if valid
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await axios.post(`${baseURL}/userapi/useraadharpan/`, formData, { headers });
      setSuccessMessage("Successfully updated");
      setIsUpdated(true); // Prevent further updates
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPanaandAadhar(btoken);
        setFormData({
          aadharcard: data.aadhar_card || "",
          pancard: data.pan_card || "",
        });

        if (data.aadhar_card && data.pan_card) {
          setIsUpdated(true); // Disable editing if data exists
        }
      } catch (error) {
        console.error("Error fetching Aadhar & PAN data:", error);
      }
    };

    fetchData();
  }, [btoken]);

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 ChangeAddress-wrap">
        <div className="wrapper">
          <h1>Aadhar-Pan Details</h1>

          {/* Success & Error Messages */}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

          <Form onSubmit={handleSubmit}>
            <div className="ChangeAddress-form-wrap">
              {/* PAN Card Input */}
              <div className="reg-1">
                <b>Pan</b>
                <input
                  type="text"
                  name="pancard"
                  value={formData.pancard}
                  onChange={handleInputChange}
                  className="text-f-1"
                  maxLength="10"
                  required
                  disabled={isUpdated} // Disable if already updated
                />
              </div>

              {/* Aadhar Card Input */}
              <div className="reg-1">
                <b>Aadhar</b>
                <input
                  type="text"
                  name="aadharcard"
                  value={formData.aadharcard}
                  onChange={handleInputChange}
                  className="text-f-1"
                  maxLength="12"
                  required
                  disabled={isUpdated} // Disable if already updated
                />
              </div>

              {/* Buttons */}
              <div className="reg-1 w-100 text-center">
                {!isUpdated && <button className="btn-2">Save</button>} &nbsp;&nbsp;
                <Link to="/MyAccountStatus">
                  <button className="btn-1">Cancel</button>
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default MyAccountStatus;
