import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/DashboardSlider.css";
import { baseURL } from "../services/Aut.post.js";
import { useNavigate } from "react-router-dom";

function GameStepOne() {
  const [gameval, setgameval] = useState(null);
  const [addid, setAddid] = useState(null);
  const [sponsoredData, setSponsoredData] = useState({});
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("user");
  let btoken = null;

  try {
    btoken = accessToken ? JSON.parse(accessToken).access : null;
  } catch (error) {
    console.error("Error parsing user token:", error);
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: btoken ? `Bearer ${btoken}` : "",
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gameValue = urlParams.get("contest");

    if (!gameValue) {
      console.error("No contest ID found in URL.");
      return;
    }

    setgameval(gameValue);

    const formData = { contestid: gameValue };

    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/userapi/contestsponser/`, formData, { headers });

        const data = response.data;
        console.log("Fetched Data:", data);

        localStorage.setItem("contest_name", JSON.stringify(data.spoContestName || ""));
        localStorage.setItem("sponcer_name", JSON.stringify(data.sponsorby || ""));
        localStorage.setItem("game_demo_link", JSON.stringify(data.gamedemolink || []));
        localStorage.setItem("winningtype", JSON.stringify(data.winningtype || ""));
        localStorage.setItem("prize", JSON.stringify(data.prize || ""));
        localStorage.setItem("AnnouncementDate", JSON.stringify(data.AnnouncementDate || ""));
        localStorage.setItem("No_of_winners", JSON.stringify(data.No_of_winners || 0));
        localStorage.setItem("stageno", JSON.stringify(data.stageno || 1));
        localStorage.setItem("moves", JSON.stringify(data.moves || 1));
        localStorage.setItem("time", JSON.stringify(data.time || 1));
        localStorage.setItem("contestTockesn", JSON.stringify(data.contestTockesn || 0));
        localStorage.setItem("contestid", gameValue);
        localStorage.setItem("Basegame", JSON.stringify(data.Basegame || {}));

        setSponsoredData(data);
        setAddid(data.spoAdid || null);
      } catch (error) {
        console.error("Error fetching contest data:", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = async () => {
    if (!addid) {
      console.error("Sponsor ID (spoid) is missing.");
      return;
    }

    const formDataCont = { viewcount: 1, spoid: addid };

    try {
      const response = await axios.post(`${baseURL}/userapi/getallstatusupdates/`, formDataCont, { headers });

      console.log("API Response:", response.data);
      if (typeof response.data !== "object") {
        console.error("Unexpected API Response Format:", response.data);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }

    navigate(`/GameStepTwo?contest=${gameval}&stage=1`);
  };

  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper paragraph-1">
          <h1>{sponsoredData.spoContestName || "Game Title"}</h1>
          <h6>Sponsored by {sponsoredData.sponsorby || "Unknown Sponsor"}</h6>
          <div className="DashboardSlider">
            <a href={sponsoredData.sponsorlink} target="_blank" rel="noopener noreferrer">
              <img src={`${baseURL}/media/${sponsoredData.sponsorimage}`} alt="contest" />
            </a>
            <p>{sponsoredData.sponsormsg || "No Sponsor Message"}</p>
            <div className="text-center pt-3 pb-5">
              <button onClick={handleClick} className="btn-2">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameStepOne;
