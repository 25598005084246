import React, { useEffect, useState } from "react";
import { baseURL, getAllResultDetailsThirdarrg } from "../services/Aut.post.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Styles/ExtraOffers.css";
import InnerBanner from "./InnerBanner";

const SurveyDetails = () => {
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const { id, cname } = useParams();

  const [survey, setSurvey] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const [imageUploads, setImageUploads] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await getAllResultDetailsThirdarrg(id, cname, btoken);
  //       if (data && data.title) {
  //         setSurvey(data);
  //         if (data.user_exists) {
  //           setShowPopup(true);
  //         }
  //       } else {
  //         console.error("Invalid data format:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [id, cname, btoken]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Construct the API URL dynamically
        const apiUrl = `${baseURL}/tockens_api/getluckGamedetails/${id}/${cname}/`;
  
        // Fetch data from the API
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${btoken}`, // Include the Bearer token
          },
        });
  
        const data = response.data;
  
        if (data && data.title) {
          setSurvey(data); // Set survey data in state
          if (data.user_exists) {
            setShowPopup(true); // Show popup if user exists
          }
        } else {
          console.error("Invalid data format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [id, cname, btoken]);
  

  const closePopup = () => {
    setShowPopup(false);
    navigate("/Survey-Categories");
  };

  const SurveyErrorMsg = () => {
    const checksurvey = survey?.user_exists;

    return (
      <>
        {checksurvey ? (
          <div className="popup-overlay">
            <div className="popup-content error-box">
              <p style={{ color: "red", fontSize: "18px", fontWeight: "500" }}>
                *You have already submitted the survey.
              </p>
              <button onClick={closePopup} className="close-popup-btn">
                Close
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const SurveyConducted = () => {
    const checksurvey = survey?.user_exists;

    return (
      <>
        {checksurvey ? (
          <button className="survey-btn disabled" disabled>
            Submit
          </button>
        ) : (
          <button
            className={isFormValid ? "survey-btn" : "survey-btn disabled"}
            type="submit"
            disabled={!isFormValid}
          >
            Submit
          </button>
        )}
      </>
    );
  };

  useEffect(() => {
    if (survey) {
      const allQuestionsFilled = survey.questions.every((question) => {
        const questionId = question.question_id;

        switch (question.question_type) {
          case "multiple_choice":
          case "radio":
            return !!selectedOptions[questionId];
          case "text":
            return textAnswers[questionId]?.trim() !== "";
          case "image":
            return !!imageUploads[questionId];
          default:
            return true;
        }
      });

      setIsFormValid(allQuestionsFilled);
    }
  }, [survey, selectedOptions, textAnswers, imageUploads]);

  const handleOptionChange = (questionId, optionText) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [questionId]: optionText,
    }));
  };

  const handleTextChange = (questionId, text) => {
    setTextAnswers((prevState) => ({
      ...prevState,
      [questionId]: text,
    }));
  };

  const handleImageUpload = (questionId, event) => {
    const file = event.target.files[0];
    setImageUploads((prevState) => ({
      ...prevState,
      [questionId]: file, // Store the actual file here
    }));
  };

  const renderOptions = (question) => {
    switch (question.question_type) {
      case "multiple_choice":
        return (
          <div className="select-options">
            <select
              id={`dropdown-${question.question_id}`}
              value={selectedOptions[question.question_id] || ""}
              onChange={(e) =>
                handleOptionChange(question.question_id, e.target.value)
              }
            >
              <option value="">Select an option</option>
              {question.options &&
                question.options.map((option, index) => (
                  <option key={index} value={option.option_text}>
                    {option.option_text}
                  </option>
                ))}
            </select>
          </div>
        );
      case "radio":
        return (
          <div className="radio-options">
            {question.options &&
              question.options.map((option, index) => (
                <div key={index}>
                  <input
                    type="radio"
                    id={`radio-${index}-${question.question_id}`}
                    name={question.question_id}
                    value={option.option_text}
                    checked={
                      selectedOptions[question.question_id] ===
                      option.option_text
                    }
                    onChange={() =>
                      handleOptionChange(
                        question.question_id,
                        option.option_text
                      )
                    }
                  />
                  <label htmlFor={`radio-${index}-${question.question_id}`}>
                    {option.option_text}
                  </label>
                </div>
              ))}
          </div>
        );
      case "text":
        return (
          <div>
            <input
              className="text-input"
              type="text"
              id={`text-${question.question_id}`}
              value={textAnswers[question.question_id] || ""}
              onChange={(e) =>
                handleTextChange(question.question_id, e.target.value)
              }
            />
          </div>
        );
      case "image":
        return (
          <div>
            <input
              className="file-input"
              type="file"
              id={`image-${question.question_id}`}
              accept="image/*"
              onChange={(e) => handleImageUpload(question.question_id, e)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create a FormData object to handle files
    const formData = new FormData();
    // Append selected options and text answers as form data
    Object.keys(selectedOptions).forEach((key) => {
      formData.append(`selectedOptions[${key}]`, selectedOptions[key]);
    });
    Object.keys(textAnswers).forEach((key) => {
      formData.append(`textAnswers[${key}]`, textAnswers[key]);
    });

    // Append image uploads as form data
    Object.keys(imageUploads).forEach((key) => {
      const file = imageUploads[key];
      if (file) {
        formData.append(`imageUploads[${key}]`, file);
      }
    });

    // Make the axios request with FormData
    axios
      .post(`${baseURL}/tockens_api/surveyresponse/${id}/${cname}`, formData, {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data", // Specify the content type for file uploads
        },
      })
      .then(() => navigate("/Survey-Confrim"))
      .catch((error) => {
        console.error("Error submitting response:", error);
        alert("Error submitting response");
      });
  };

  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper paragraph-1">
        <h1>Survey's</h1>
        {showPopup && <SurveyErrorMsg />}
        <form onSubmit={handleSubmit}>
          <div>
            {survey ? (
              <div>
                <h2>{survey.title}</h2>
                <p>{survey.description}</p>
                <div className="survey-list-container">
                  {survey.questions.map((question) => (
                    <div
                      key={question.question_id}
                      className="survey_container"
                    >
                      <div className="survey-item-wrap">
                        {question.question_text}
                      </div>
                      {renderOptions(question)}
                    </div>
                  ))}
                  <p className="surevy-p-tag">
                    “ Your Personal data will not be Shared with anyone ”
                  </p>
                </div>
                {SurveyConducted()}
              </div>
            ) : (
              <p>Loading survey details...</p>
            )}
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

export default SurveyDetails;
